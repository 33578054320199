.set-header-bottom {
  border-bottom: 1px solid #bbbac3;
}

.set-header-drop {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.header-brand-logo img {
  width: 90px;
}

.dropdown-menu.show {
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
  border: none;
}

.profile-content-set {
  --bs-dropdown-item-padding-y: 10px;
  --bs-dropdown-item-padding-x: 16px;
  font-size: 14px;
  line-height: 24px;
  transition: 0.25s ease-in-out;
}

.user-pro-image {
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 20px;
  border-radius: 50%;
}

.nav-link {
  --bs-nav-link-padding-y: 20px;
  --bs-nav-link-padding-x: 15px;
  --bs-navbar-nav-link-padding-x: 1rem;
}

@media (max-width: 991px) {
  .nav-link {
    --bs-nav-link-padding-y: 10px;
  }
}
