body {
  font-family: 'Inter', 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #6e7191;
  letter-spacing: 0.02em;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $color-neutral-700;
}

h1 {
  margin-top: 0px;
  margin-bottom: 0;
  color: #282c3f;
  font-size: 2.5rem;
  line-height: 4rem;
  font-weight: 400;
  letter-spacing: 0.02em;
}

h2 {
  // margin-top: 0px;
  // margin-bottom: 0;
  // color: #282c3f;
  // // color: $color-neutral-800;
  // font-size: 2rem;
  // line-height: 3rem;
  // font-weight: 400;
  // letter-spacing: 0.02em;

  margin-top: 0px;
  margin-bottom: 20px;
  color: #282c3f;
  font-size: 2rem;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 0;
}

h3,
.h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #282c3f;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  font-weight: 400;
}

h4 {
  margin-top: 0px;
  margin-bottom: 0.5rem;
  color: #282c3f;
  font-size: 1.4rem;
  line-height: 3rem;
  font-weight: 400;
  letter-spacing: 0.02em;
}
h5 {
  font-weight: 400;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  margin: 0;
  overflow: hidden;
}

span {
  color: $color-neutral-700;
}

.paragraph-large {
  font-size: 1.142rem;
  line-height: 1.714em;
}
