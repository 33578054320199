@import '../abstracts/mixins';

.home-advantage-images-wrapper {
  position: relative;
  display: flex;
  margin-right: 20px;
  padding-left: 50px;
  align-items: flex-end;
  perspective: 1000px;
}

.home-advantage-1 {
  position: relative;
  z-index: 1;
}

.home-advantage-2 {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.startup-2 {
  top: 7%;
  left: 70%;
}

.login-text_box {
  margin-top: 2rem;
}

.sign-up__Link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .home-advantage-2 {
    top: 40%;
    left: 45%;
  }

  .startup-2 {
    top: 35%;
    left: 70%;
    width: 75%;
  }
}

@media (max-width: 991px) {
  .home-advantage-2 {
    top: 60%;
  }

  .startup-2 {
    top: 50%;
    left: 70%;
    width: 60%;
  }
}
.or-login {
  display: flex;
  align-items: center;
}
.img_tag_label {
  padding-bottom: 8px;
}

.banner-type_flex {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  justify-content: center;
}
.banner-type_flex-type {
  display: flex;
  align-items: baseline;
}
