.set-startup-box {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    border: 1px solid #e0e0e0;
    width: 100%;
    text-align: center;
    padding: 50px;
    border-radius: 15px;
    font-weight: 600;
    background-color: #fff;
}
