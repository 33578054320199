//============documents=============//
.bg-color-caedall {
  background-color: #ffeae9;
  font-size: 25px;
  justify-content: space-around;
  display: flex;
}
.icon-documentall {
  color: #ee4437;
  font-size: 25px;
  padding: 5px 0px;
  margin: 5px !important;
}
.document-icon-color {
  color: #ee4437;
  font-size: 25px;
}
// .doc_kyc_img {
//   height: 170px !important;
//   width: 290px !important;
// }
