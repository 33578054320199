/*network css start*/

.p-itm {
  background-color: #fff;
  padding: 15px 21px;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(20, 20, 43, 0.05);
}
.p-itm .itm-inner > .top {
  display: flex;
  align-items: center;
}
.p-itm .itm-inner > .top > .avtr img {
  height: 36px;
  width: 36px;
  border-radius: 50em;
}
.p-itm .itm-inner > .top > .dtls {
  flex-grow: 1;
  margin-left: 15px;
}
.p-itm .itm-inner > .top > .dtls > .name {
  font-weight: 700;
  color: #000;
}

.spacer {
  flex-grow: 1 !important;
}
.p-itm .itm-inner > .cnt {
  margin: 5px 0;
}

.v-divider {
  border-color: rgba(0, 0, 0, 0.12);
}

.v-divider {
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0;
  transition: inherit;
  margin: 0px;
}
.p-itm .itm-inner > .acts {
  margin-top: 10px;
  margin-bottom: 10px;
}

.social-link-like a i {
  font-size: 20px;
  margin-right: 10px;
}
.social-link-comment a i {
  font-size: 20px;
  margin-right: 10px;
}

/*investors team css start*/
@media screen and (max-width: 991px) {
  .team-members-grid {
    margin-bottom: 63px !important;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr !important;
  }
}
@media screen and (max-width: 767px) {
  .team-members-grid {
    margin-bottom: 52px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr !important;
  }
}

.team-section-collection-list-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 0;
  flex: 1;
}
.team-wrapper {
  position: relative;
  overflow: visible;
  margin-top: 20px;
}

.bg.team-1 {
  position: absolute;
  left: -303px;
  top: -197px;
  z-index: -1;
  max-width: 785px;
}

.bg.team-2 {
  position: absolute;
  right: -277px;
  bottom: -245px;
  z-index: -1;
  max-width: 785px;
}

.team-members-grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 75px;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 52px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.team-section-item {
  padding-top: 83px;
}

.card {
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(20, 20, 43, 0.05);
}

.card.team-section-item-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  padding-right: 38px;
  padding-bottom: 50px;
  padding-left: 38px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, transform 300ms ease;
  transition: box-shadow 300ms ease, transform 300ms ease,
    -webkit-transform 300ms ease;
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card.team-section-item-card:hover {
  box-shadow: 0 12px 18px 0 rgba(20, 20, 43, 0.05);
  -webkit-transform: scale3d(1.01, 1.01, 1.01);
  transform: scale3d(1.01, 1.01, 1.01);
}
.card.team-section-item-card:hover .title.card-team-section-item-name {
  color: rgb(255, 57, 81);
}

h2.title.card-team-section-item-name {
  margin-bottom: 7px;
  font-size: 25px !important;
  line-height: 1.346em;
  font-weight: 700;
  letter-spacing: 0.02em;
}
.card-team-section-item-rol {
  margin-bottom: 15px;
  color: #ee4437;
  font-size: 18px;
}

.paragraph.card-team-section-item {
  margin-bottom: 0px;
  font-size: 16px;
}

.team-section-item-card-content {
  margin-bottom: 24px;
  color: #6e7191;
  text-decoration: none;
}
.image-wrapper.card-team-section-item {
  width: 180px;
  min-height: 180px;
  min-width: 180px;
  margin-top: -83px;
  margin-bottom: 22px;
  border-radius: 1000px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.image-wrapper.card-team-section-item img {
  width: 100%;
}

.image-wrapper {
  overflow: hidden;
}

.social-link.blue a:first-child {
  background-color: #1781fe;
  box-shadow: 0 6px 8px 0 rgba(21, 116, 239, 0.21);
}

.social-link.blue a:nth-child(2) {
  background-color: #009eff;
  box-shadow: 0 6px 8px 0 rgba(27, 186, 255, 0.27);
}

.social-link.blue a:nth-child(3) {
  background-color: #157ee7;
  box-shadow: 0 6px 8px 0 rgba(21, 126, 231, 0.34);
}

/*investors team css end*/

/*investors profile css start*/

.team-top-content {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  // flex-wrap: wrap;
  //padding: 30px;
}
.team-main-content {
  max-width: 477px;
}

.title.team-name {
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .textinput.team-bio {
    width: 100%;
  }
}
.team-rol {
  margin-bottom: 18px;
  color: #ee4437;
  font-size: 20px;
  line-height: 1.1em;
}

.paragraph.team-excerpt {
  margin-bottom: 28px;
}

.team-social-media-grid {
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  grid-column-gap: 24px;
  -ms-grid-columns: auto auto auto;
  grid-template-columns: auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  display: grid;
}

a.docs-headding {
  font-size: 22px;
  font-weight: 600;
}

.current-user-ts {
  padding: 0px;
  display: flex;
  margin-top: 5px;
  align-items: center;
}

.current-user-imgs {
  width: 80px;
}
.current-user-imgs > img {
  width: 60px;
  height: 60px;
  border-radius: 50em;
  margin-right: 13px;
}

/*investors profile css end*/

/*Enabler css start*/

.enabler-btn button.btn-sky.save-btn {
  height: 65px !important;
  width: 150px !important;
}
.contact-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.split-content.contact-content {
  max-width: 45%;
  margin-right: 20px;
  color: #333333;
}

.split-content.contact-card {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 660px;
  justify-content: center;
  align-items: center;
}

.card.contact-form {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 660px;
  //   min-height: 694px;
  padding: 72px 48px;
  border-radius: 34px;
  box-shadow: 0 54px 54px 0 rgba(232, 48, 81, 0.02),
    0 1px 104px 0 rgba(20, 20, 43, 0.04), 0 98px 66px 0 rgba(19, 18, 66, 0.02);
}

.contact-form {
  display: -ms-grid;
  display: grid;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 22px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.contact-form-block {
  display: flex;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.bg.contact {
  max-width: 1136px;
  z-index: -1;
  position: absolute;
  bottom: -145px;
}
.contact-form .input {
  color: #333333 !important;
}

.text-area:focus {
  border-color: #ee4437 !important;
  box-shadow: 0 2px 11px 0 rgba(244, 38, 62, 0.06),
    0 4px 10px 0 rgba(31, 37, 89, 0.07);
  color: #ee4437;
  outline: none;
  font-weight: 500;
}

.contact-form .text-area {
  max-height: 250px;
  max-width: 100%;
  min-height: 169px;
  min-width: 100%;
  grid-column-start: span 2;
  margin-bottom: 0px;
  padding: 28px 21px;
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(19, 18, 66, 0.07);
  -webkit-transition: box-shadow 350ms ease, color 350ms ease,
    border-color 350ms ease;
  transition: box-shadow 350ms ease, color 350ms ease, border-color 350ms ease;
  color: #333333;
  font-size: 18px;
  line-height: 1.111em;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.contact-form .w-node-full-length {
  grid-column: span 2;
}

.button-primary.contact-form-button {
  display: flex;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
}

input.w-button {
  -webkit-appearance: button;
}

.contact-form-link {
  padding: 0px !important;
  background-color: transparent !important;
}

.link-arrow {
  margin-left: 8px;
  font-family: 'Icons Marketing Template', sans-serif;
  font-size: 13px;
  line-height: 1em;
  font-weight: 400;
}

.split-content.contact-content h1 {
  margin-top: 0px;
  margin-bottom: 16px;
  color: #14142b;
  font-size: 60px;
  line-height: 1.233em;
  font-weight: 700;
  letter-spacing: 0.02em;
}

@media only screen and (max-width: 991px) {
  .split-content.contact-card {
    max-width: 100%;
  }
  .split-content.contact-content {
    max-width: 660px;
    margin-right: 0px;
    margin-bottom: 60px;
  }
  .card.contact-form {
    max-width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    flex: 0 auto;
  }
  .contact-form-block {
    flex: 1;
  }
  .contact-form {
    flex: 1;
  }
  .contact-wrapper {
    flex-direction: column;
    align-items: stretch !important;
  }
}
@media only screen and (max-width: 767px) {
  .split-content.contact-content {
    margin-bottom: 50px;
  }
  .check_box {
    margin-left: -20px;
  }
  .card.contact-form {
    // min-height: 922px;
    padding: 50px 40px;
    border-radius: 28px;
  }
  .contact-form {
    grid-template-columns: 1fr;
  }
  .contact-form .w-node-full-length {
    grid-column: unset;
  }
}

/*startup-basic css start*/

section.startup-basic {
  background-color: #f5f7fc;
  margin: 0;
  padding: 50px 0px;
}

.sector-startup {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.start-up-section {
  padding: 30px;
}

form.card-free-consultation-form-grid.details {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 25px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

form.card-free-consultation-form-grid.details .text-area {
  max-height: 250px;
  max-width: 100%;
  min-height: 169px;
  min-width: 100%;
  margin-bottom: 0px;
  padding: 28px 21px;
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(19, 18, 66, 0.07);
  -webkit-transition: box-shadow 350ms ease, color 350ms ease,
    border-color 350ms ease;
  transition: box-shadow 350ms ease, color 350ms ease, border-color 350ms ease;
  color: #ee4437;
  font-size: 18px;
  line-height: 1.111em;
  font-weight: 500;
  letter-spacing: 0.02em;
}

@media only screen and (max-width: 991px) {
  form.card-free-consultation-form-grid.details {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .text3-invest {
    margin-top: -20px !important;
  }
  .pointer_icon {
    margin-top: 7px;
  }
}

@media only screen and (max-width: 390px) {
  .mg-5 {
    margin-bottom: 4rem;
  }
}
.check_box {
  margin-left: -30px;
}

.bar-nav.active {
  background-color: #fff;
  transition: color 300ms ease, background-color 300ms ease;
  color: #ee4437 !important;
  font-weight: 700;
  border-bottom: 2px solid #ee4437 !important;
}

.bar-nav:hover {
  transition: color 300ms ease, background-color 300ms ease;
  color: #ee4437 !important;
  // font-weight: 700;
  border-bottom: 2px solid #ee4437 !important;
}
//-------------css for investment --------------------//

.set-invest-page {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.input-form-set-invest {
  margin-bottom: 4rem;
}

.img-invest {
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 5px;
}

.text-invest {
  font-size: 26px;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
  color: #14142b;
}

.text1-invest {
  font-size: 16px;
  font-weight: 800;
  //margin-bottom: -1.5rem;
}

.text2-invest {
  font-size: 14px;
  margin-top: -3rem;
}

.input-box-invest {
  border-radius: 2px solid #1b1a1a;
  height: 20%;
  width: 60%;
}

.icon-m-invest {
  font-size: 18px;
  color: #666;
}

.text3-invest {
  font-size: 16px;
  font-weight: 600;
}

.text4-invest {
  font-size: 16px;

  font-weight: 800;
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.border-line-set {
  border-bottom: 1px solid #d2d2d2;
}

.textp-invest {
  font-size: 15px;
  color: #666;
  margin-bottom: -1rem;
  margin-top: -0.5rem;
}

.dis-input-invest {
  display: flex;
}

.input-box-size1 {
  margin-left: 9rem;
}
.input-box-size2 {
  margin-left: 0.5rem;
}
.input-box-size3 {
  margin-left: 0.5rem;
}

.input-box-size {
  padding: 0px 90px 0px 24px;
}

.border-box-invest {
  border: 2px solid #aca9a9;
}

.box-inpute-text-invest {
  margin-left: 0.5rem;
  font-weight: 600;
}
.box-inpute-text-invest1 {
  font-weight: 600;
}

.input-box-invest-content {
  padding: 35px 0px 0px 0px;
}

.border-text-invest {
  border-bottom: 1px solid #999999;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.border-text-invest1 {
  border-top: 1px solid #999999;
}
.height-div-invest {
  height: 3rem;
}

.input-text-set-form {
  margin-top: 1rem;
}
.check-box-invest {
  border: 2px solid #999999;
}

.text-set-div-invest {
  margin-left: 0.5rem;
}
.text-button-set {
  display: flex;
}
.margin-in-button {
  margin-left: 8rem;
}
.personal-information-invest {
  margin-left: 3rem;
}

.box {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  height: 70%;
  width: 100%;
  padding: 10px 20px;
}
.set_color {
  color: #ee4437;
  font-weight: 800;
}
.set_btn {
  display: flex;
  justify-content: end;
}
