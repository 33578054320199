*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 87.5%; //1 rem = 14px; 14px/16px = 87.5%

  @include respond(tab-land) {
    // width < 1200?
    font-size: 75%; //1 rem = 12px, 12/16 = 75%
  }

  // @include respond(tab-port) { // width < 900?
  //     font-size: 62.5%; //1 rem = 10px, 10/16 = 62.5%
  // }

  @include respond(big-desktop) {
    font-size: 100%; //1rem = 16, 16/16
  }
}

body {
  box-sizing: border-box;
  padding: 3rem;
  margin: 0;

  @include respond(tab-port) {
    padding: 0;
  }
}

::selection {
  background-color: $color-primary;
  color: $color-white;
}

a {
  transition: color 300ms ease;
  color: #282c3f;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #282c3f;
  text-decoration: underline;
}

a:active,
a:hover {
  outline: 0;
}
