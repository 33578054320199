.card {
  margin-bottom: 1.5rem;
  border-color: var(--bs-card-border-color);
  box-shadow: var(--bs-card-box-shadow);
}

.card {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-gray-200);
  --bs-card-border-radius: var(--bs-border-radius-lg);
  --bs-card-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  --bs-card-inner-border-radius: calc(var(--bs-card-border-radius) - var(--bs-card-border-width));
  --bs-card-cap-padding-y: 1rem;

  --bs-card-cap-padding-x: var(--bs-card-spacer-x);
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-white);
  --bs-card-img-overlay-padding: 1.5rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: 1rem;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-top: calc(var(--bs-card-spacer-y) * 0.5);
  padding-bottom: calc(var(--bs-card-spacer-y) * 0.5);
}

.card>* {
  flex-shrink: 0;
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: none !important;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.card-table {
  margin-bottom: 0;
}

.table-sm {
  font-size: 0.8125rem;
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-gray-200);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: var(--bs-gray-100);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: var(--bs-gray-100);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: var(--bs-gray-100);
  width: 100%;
  margin-bottom: 1.5rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  border-top: none !important;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}

th:first-child {
  padding-left: var(--bs-card-spacer-x) !important;
}

.card-table thead th {
  border-top-width: 0;
}

.table-sm thead th {
  font-size: 0.625rem;
}

.table thead th,
tbody td,
tbody th {
  vertical-align: middle;
  text-align: center;
}

.table thead th {
  background-color: var(--bs-gray-100);
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: var(--bs-gray-600);
}

.table [data-sort],
// .table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}

.table td,
.table th {
  border-top: 1px solid var(--bs-table-border-color);
  border-bottom: 0;
}

.table-sm> :not(caption)>*>* {
  padding: 1rem;
}

.table> :not(caption)>*>* {
  padding: 8px 1rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

.invest_name_td {
  text-align: left !important;
}

.invest_td-data {
  color: #67748e;
  font-weight: 500;
  font-size: 0.75rem;
}

.invest_th {
  width: auto;
  padding: 12px 24px 10px !important;

  font-size: 0.65rem !important;
  font-weight: 700 !important;
  border-bottom: 0.0625rem solid rgb(233, 236, 239);
  opacity: 0.7;
  background: transparent;
  color: rgb(131, 146, 171) !important;
  background-color: #fff !important;
}

.invest-head {
  margin: 0px 0px 0.35em;
  font-size: 1rem;
  line-height: 1.625;
  font-weight: 500;
  letter-spacing: 0.0075em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}

.optionListContainer.displayBlock {
  position: absolute;
  top: 45px !important;
  left: 4px !important;
}

.optionListContainer.displayNone {
  position: absolute;
  top: 45px !important;
  left: 4px !important;
}

.optionListContainer {
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 45px !important;
  left: 4px !important;
}

.dropdown-toggle::after {
  display: none;
}

.mess_pop-head {
  display: flex;
  font-size: 26px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 10px;
}

.mess_btn-flex {
  display: flex;
  justify-content: right;
}

.search-icon_in {
  position: absolute;
  top: 22px;
  left: 16px;
}

.addInvestor {
  ul {
    li {
      flex-grow: 1;

      button {
        width: 100%;
        color: #000 !important;
        border-radius: 0 !important;
        border-bottom: 2px solid transparent;
        padding: 15px !important;

        &.active {
          background-color: #FFF5F5 !important;
          border-bottom: 2px solid #EF2D2E !important;
        }
      }
    }
  }
}

.startup_accordian {
  .accordion-button {
    box-shadow: none !important;
    padding: 10px 15px !important;
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
  }

  .accordion-body {
    font-size: 12px;
    padding: 5px 15px !important;
  }
}

.dashboard_menu {
  .nav-tabs {
    .nav-item {
      margin: 0 20px;

      button {
        border-radius: 0 !important;
        border-bottom-width: 2px !important;
        padding-bottom: 8px !important;
      }
    }
  }
}

.profile_sidebar.offcanvas.offcanvas-start {
  width: 300px !important;

  .btn-close {
    box-shadow: none;
  }
}

.view_all_tabs {
  .nav.nav-tabs {
    border-bottom: 0 !important;
  }

  .nav-tabs .nav-link {
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
    border: 1px solid rgba(0, 0, 0, 0.7);
    padding: 2px 25px !important;
    border-radius: 30px !important;

    &:hover {
      border-color: rgba(0, 0, 0, 0.7);
    }
  }

  .nav-tabs .nav-link.active {
    border: 1px solid #EF2D2E !important;
    padding: 2px 25px !important;
    border-radius: 30px !important;
    color: #EF2D2E !important;
    background-color: #EE44371A !important;
  }
}