@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


.dropdown-menu {
  opacity: 0;
  clip-path: inset(0 0 100% 0);
  /* Initially hides the bottom part */
  transition: clip-path 0.5s ease-out, opacity 0.3s ease;
}

.dropdown-menu.show {
  opacity: 1;
  clip-path: inset(0 0 0 0);
}


.after-border::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: 7%;
  border-bottom: 1px solid rgb(221, 219, 219);
}

.custom-dropdown {
  border: 1px solid #e0e0e0 !important;
  /* Adjust border color */
}

.custom-dropdown.dropdown-menu.show {
  animation: fade .4s normal;
}

@keyframes fade {
  0% {
    margin-top: 20px;
  }

  80% {
    opacity: 1;
  }

  100% {
    margin-top: 0;
  }
}


.react-select__data {
  background-color: red;
  color: black;
}

.payment_modal .modal-dialog {
  width: 400px !important;
}

.kyc_modal .modal-dialog {
  width: 700px !important;
}

.register_modal .modal-dialog {
  width: 500px !important;
}

/* .register_modal .modal-content{
  background-color: #fee2e2;
} */



.Toastify__toast {
  @apply rounded-lg text-sm shadow-md px-4 py-[-10px];
  width: auto !important;
  height: auto !important;
  max-width: 70%;
  max-height: 30%;
  background-color: red;
}

.Toastify__toast--success {
  @apply bg-green-500;
}

.Toastify__toast--error {
  @apply bg-red-500;
}

.Toastify__toast-body {
  @apply text-[#2C3A9B];
}

.Toastify__progress-bar {
  @apply bg-blue-400;
}

.clip-corner {
  clip-path: polygon(0 0, 100% 0, 0 100%);
  background: linear-gradient(to bottom right, #e5e7eb, white);
}