// Override default variables

// COLORS

$color-primary: #ee4437;
$color-primary5: #ee44375c;

$color-secondary-1: #ffc226;
$color-secondary-2: #6a35ff;
$color-secondary-3: #3f80fd;
$color-secondary-4: #ff8139;
$color-secondary-5: #3fcffd;

$color-tertiary-1: #ffeae9;
$color-tertiary-2: #ee443747;

$color-neutral-800: #14142b;
$color-neutral-700: #4e4b66;
$color-neutral-600: #6e7191;
$color-neutral-500: #a0a3bd;
$color-neutral-400: #d9dbe9;
$color-neutral-300: #eff0f6;
$color-neutral-200: #f5f7fc;
$color-neutral-100: #ffffff;

$color-primary-100: #888686;

$color-primary-2: #dcecf8;
$color-primary-3: #6db4e8;
$color-primary-4: #005a9b;
$color-primary-5: #edf7ff;
$color-primary-6: #262626;
$color-primary-7: #f3f3f3;

$color-primary-8: #14142b0f;
$color-primary-9: #ee443761;
$color-primary-10: #ff5757;
$color-primary-11: #f9f9f9;
$color-primary-12: #fff1f1;
$color-primary-13: #9d9d9d;
$color-primary-14: #f6f6f6;
$color-primary-15: #c6c6c6;
$color-primary-16: #ccc;
$color-primary-17: #6db4e633;
$color-primary-18: #3c9ee5;
$color-primary-19: #31a2d8;
$color-primary-20: #246584;
$color-primary-21: #7fbdea;
$color-primary-22: #752831;
$color-primary-23: #6a35ff;
$color-primary-24: #f6e3e1;
$color-primary-25: #1d1d1f;
$color-primary-26: #e57a6f;
$color-primary-27: #a5a3a3;
$color-primary-28: #7c7c7c;
$color-primary-29: #4f4f4f;
$color-primary-30: #eeeeee;
$color-primary-31: #e8e8e8;
$color-primary-32: #bdbdbd;
$color-primary-33: #d5d5d5;
$color-primary-34: #e4e4e4;
$color-primary-35: #8dad60;

$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size: 1.6rem;
