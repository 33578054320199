// .footer-quick-link {
//   display: flex;
//   justify-content: space-between;
//   flex: 1;
//   flex-wrap: wrap;
//   margin-top: 40px;
// }

// .footer-heading {
//   margin-top: 0px;
//   color: #14142b;
//   font-size: 1.5rem;
//   line-height: 2rem;
//   letter-spacing: 0.02em;
//   font-weight: 500;
// }
// .social-link a {
//   width: 40px;
//   height: 40px;
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #ee4437;
//   border-radius: 50%;
//   margin-right: 30px;
//   margin-top: 20px;
//   text-decoration: none;
//   box-shadow: 0 8px 22px 0 #ee443747;
//   transition: 0.3s;
//   margin-bottom: 10px;
// }
// .social-link a:hover {
//   transform: translate3d(0px, -6px, 0.01px);
// }
// .social-link a i {
//   color: #fff;
// }
// .footer-logo img {
//   width: 100px;
// }
// .footer-tag-line {
//   margin-top: 8px;
// }

// @media (max-width: 576px) {
//   .social-link {
//     margin-top: 20px !important;
//   }
//   .footer-quick-link {
//     margin-top: 20px !important;
//   }
// }

$gray-300: #DADADA;
$primary: #EE4437;
$dark: #14142B;
$white: #fff;

footer {
  border-top: 1px solid $gray-300;
  padding: 60px 200px;

  // @media(min-width:991px) and (max-width:1190px) {
  //   padding: 40px 100px !important;
  // }

  @media(min-width:1200px) and (max-width:1790px) {
    padding: 40px 150px;

  }

  @media(max-width:1190px) {
    padding: 15px;

  }

  hr{
    margin: 2.5rem 0 1rem 0
  }
}

.footer-column {

  h3 {
    font-size: 16px;
    font-weight: 700;
    color: $primary;
    margin-bottom: 20px;

    @media(max-width:1024px) {
      margin: 20px 0;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      font-size: 16px;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 5px 0;

      @media (min-width:1200px) and (max-width:1790px) {
        font-size: 14px; margin: 0 0 0 0;
      }

      a {
        color: $dark;
        text-decoration: none;
        font-size: 14px;

        &:hover {
          color: $primary;
        }
      }
    }
  }

}

.social-group {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;

  li {
    display: inline-block;
    flex: none !important;
    margin-right: 15px;
    @media (min-width:1200px) and (max-width:1790px) {
      margin-right: 5px !important;
    }
    & a {
      display: inline-flex;
      font-size: 15px;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }
  }
}

.footer-logo-column p {
  margin-top: 50px;
  max-width: 60%;

  @media (min-width:1200px) and (max-width:1690px) {
    max-width: 90%;
  }

  @media(max-width:1190px) {
    max-width: 100%;

  }
}

@media(min-width:1200px){
  .footer-logo-column img,.navbar-brand img{ max-width: 100%;}
}