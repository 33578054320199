.progresh h3 {
  font-size: 25px;
  color: #000;
}

.progressBar {
  position: relative;
}

.progressBar h4 {
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 7px;
  margin-bottom: 0.33em;
  color: #e57a6f;
}

.progressBarcontainer {
  width: 100%;
  height: 6px;
  background: #f6f6f6 !important;
  overflow: hidden;
  border-radius: 0.3em;
  margin: 10px 0px;
}

.progressBarValue {
  height: 6px;
  float: left;
  border-radius: 0.3em;
  background: #e55d87;
  background: -moz-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: -webkit-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: linear-gradient(135deg, #e57a6f 0%, #e57a6f 100%);
}

.speech-bubble {
  font-size: 14px;
  position: absolute;
  top: 0px;
  font-weight: 600;
  text-align: center;
  border-radius: 0.3em;
  right: 0px;
}

.invest-repeat {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: #e57a6f;
  }

  a,
  span {
    font-size: 12px;
    font-weight: 400;
    color: #a5a3a3;
    margin-left: auto;
  }
}
